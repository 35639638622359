<template>
  <v-main>

    <v-container
        id="review-dependents-container"
        fluid
        tag="section"
    >

      <base-material-card
          icon="mdi-account-multiple-outline"
          class="px-5 py-5"
      >
        <v-card-text v-if="errors.length">
          <v-alert color="error"
                   dark
                   outlined
                   :key="error.id"
                   show variant="danger"
                   v-for="error in errors">
            {{ error }}
          </v-alert>
        </v-card-text>

        <v-card-text>

          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-subtitle-2 dependent-name">Dependent Name</th>
                <th class="text-subtitle-2 member-name">Member Name</th>
                <th class="text-subtitle-2 relationship-type">Relationship</th>
                <th class="text-subtitle-2 dependent-location">Location</th>
                <th class="text-subtitle-2 dependent-updated">Updated</th>
                <th class="text-subtitle-2 dependent-action">Action</th>

              </tr>
              </thead>

              <tbody>
              <tr
                  v-for="dependent in dependents"
                  :key="dependent.id"
                  :class="dependent.status === 0 ? 'highlight-new-dependent' : ''"
              >
                <td>
                  <router-link :to="{ name: 'edit_dependent', params: {memberId: dependent.user.id, dependentId: dependent.id} }">
                    {{ dependent.names }}
                  </router-link>

                </td>
                <td>
                  <router-link :to="{ name: 'view_member', params: {memberId: dependent.user.id} }">
                    {{ dependent.user.names }}
                  </router-link>
                </td>
                <td> {{ dependent.relationType.name }} </td>
                <td> {{ dependent.city }}, {{ dependent.country }} </td>
                <td>
                  <span v-if="dependent.updated">{{ dependent.updated | formatDate }}</span>
                  <span v-else>{{ dependent.created | formatDate }}</span>
                </td>
                <td>
                  <router-link :to="{ name: 'edit_dependent', params: {memberId: dependent.user.id, dependentId: dependent.id} }">
                    Review
                  </router-link>

                </td>

              </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-card-text>

      </base-material-card>


    </v-container>

  </v-main>
</template>

<script>
import api from '@/services/api'
import constants from '@/utils/constants'

export default {
  name: 'DashboardReviewDependentsView',

  created() {
    this.fetchReviewDependents()
  },

  methods: {
    fetchReviewDependents() {
      const criteria = {
        status: constants.DEPENDENT_STATUS_ADMIN_REVIEW
      }
      api.fetchReviewDependents()
          .then(response => {
            this.dependents = response.data
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    handleError(err) {
      let errorMessage = api.parseResponseError(err, true)
      this.errors.push(errorMessage)
    }
  },

  data() {
    return {
      dependents: [],
      errors: [],
      dependentStatusMap: constants.getDependentStatusMap()
    }
  }
}
</script>


<style lang="sass">
th.dependent-updated, th.relationship-type, th.dependent-action
  width: 10%

th.dependent-location
  width: 25%

tr.highlight-new-dependent
  background-color: #F1F8E9

</style>